<template>
  <div class="TF PA2">
    <div class="XLR YC T3">
      <div class="W60 AlignC">求助标题</div>
      <div class="W20">尊享</div>
      <div class="W20">响应</div>
    </div>
    <div class="MT2" style="position:relative;height:80px;overflow: hidden;">
      <div v-for="(BB,index) in BBs" :key="index" class="XLR YC TG T3"
           :style="'position:absolute;top:'+Tops[index]+'px;height:1.8rem'">
        <div class="W60">{{BB.Title}}</div>
        <div class="W20 TE">{{BB.ZXn}}</div>
        <div class="W20 ">{{BB.DYn}}/{{BB.LookedTimes}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { CloneObject} from '@/components/SharedVues/Shared0.js';

  export default {
    name: "FYBBing",
    data() {
      return {
        Point: 0,
        BBsTemp: [{Title: '请专家分析胸部CT片', ZXn: 86, LookedTimes: 53, DYn: 8, Pic: '', Video: '', Text: ''},
          {Title: '口述材料文字整理', ZXn: 9, LookedTimes: 78, DYn: 6, Pic: '', Video: '', Text: ''},
          {Title: '临时急需100个O型血人', ZXn: 100, LookedTimes: 619, DYn: 32, Pic: '', Video: '', Text: ''},
          {Title: '谁能帮我把这个事办成了？', ZXn: 800, LookedTimes: 348, DYn: 34, Pic: '', Video: '', Text: ''},
          {Title: '有没有人能帮我解决这个难题？', ZXn: 60, LookedTimes: 31, DYn: 1, Pic: '', Video: '', Text: ''},
          {Title: '征集一个图标设计方案', ZXn: 5, LookedTimes: 252, DYn: 6, Pic: '', Video: '', Text: ''},
        ],
        TodayBBs: [],
        BBs: [{}, {}, {}],
        Timer: 0,
        Tops:
          [10, 40, 70]
      }
    },
    mounted() {
      this.BBsRefresh();
    },
    beforeUnmount() {
      clearInterval(this.Timer)
    },
    methods: {
      RollStart() {
        for (let i = 0; i < 3; i++) {
          let asd = this.Tops[i] - 1;
          this.Tops.splice(i, 1, asd);
          if (this.Tops[i] === -20) {//谁移出显示区域,就重新给谁赋值
            this.Tops.splice(i, 1, 70);
            this.Point++;
            if (this.Point === this.TodayBBs.length) this.Point = 0;
            this.BBs.splice(i, 1, this.TodayBBs[this.Point]);
          }
        }
      },
      BBsRefresh() {
        this.TodayBBs = this.BBsTemp;
        if (this.TodayBBs.length <= 3)
          this.BBs = CloneObject(this.TodayBBs);
        else
          for (let i = 0; i < 3; i++) this.BBs.splice(i, 1, this.TodayBBs[i]);
        this.Timer = setInterval(this.RollStart, 50);
      }
    }
  }
</script>

<style scoped>
  .W10 {
    width: 10vw;
  }

  .W65 {
    width: 65vw
  }
</style>
