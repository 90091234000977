<template>
    <div class="MP4 LH150 TF">
        时间:{{CurrYY.meetTimeStr}}<br>
        地点：{{CurrYY.meetAddrStr}}
        <div v-if="CurrYY.jdfy.length>0">简短附言：{{CurrYY.jdfy}}</div>
        <div class="XR MR4 YC MT2">
            <div class="TC">线下活动情况：&nbsp;</div>
            <ShowPVT :PVT="{P:CurrYY.Pic,V:CurrYY.Video,T:CurrYY.Text,Update:false}"></ShowPVT>
        </div>
    </div>
    <table style="background-color:#fff;">
        <thead>
        <tr class="LH120">
            <th>主约</th>
            <th>响应<br>情况</th>
            <th>战果</th>
            <th>履约情况</th>
            <th>评价反馈</th>
        </tr>
        </thead>
        <tbody>
        <tr class="TF">
            <td>{{CurrYY.zyUserName}}</td>
            <td>{{CurrYY.by.xy}}</td>
            <td class="W26 LH150">{{CurrYY.by.zg}}{{CurrYY.by.zg==='被约胜' && CurrYY.by.AppearanceFee>0 ? "支付尊享"+CurrYY.by.AppearanceFee+"个":""}}</td>
            <td>{{CurrYY.by.z_bylyqk}}</td>
            <td>{{CurrYY.by.z_ts}}</td>
        </tr>
        </tbody>
    </table>
    <div class="XR PA3 YC">
        <div class="TC">线下活动情况：&nbsp;</div>
        <ShowPVT :PVT="{P:CurrYY.by.Pic,V:CurrYY.by.Video,T:CurrYY.by.Text,Update:false}"></ShowPVT>
    </div>
</template>

<script>
    import ShowPVT from "../MyPVTs/ShowPVT";

    export default {
        name: "OpenHistoryBY",
        components: {ShowPVT},
        props: ['CurrYY'],
        mounted() {
            console.log(this.CurrYY)
        }

    }
</script>

<style scoped>

</style>