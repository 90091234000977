<template>
    <div>
        <div v-if="ToolDoc!==null && !ToZXGL">
            <div v-if="UserBool==='场馆人员' && ToolDoc.Cipher==='建卡申请'">
                <br>
                <MyDivider ShowStr="泛约共享卡建卡申请"></MyDivider>
                <div class="MP4 BD_Radius1 BGDeepGreen TW">
                    <div class="XLR YC BottGray">
                        <img :src="'UserUpload/1/S' + ToolDoc.UserLogo" class="AvatarWH">
                        <div class="ML4 LH200 W75" style="overflow: hidden">
                            <div class="T5 BottGray">卡主: {{ToolDoc.UserName}}</div>
                            <div>限划：{{ToolDoc.CurrTimes}}人次</div>
                            每人次＝{{ToolDoc.OneFYB}}个尊享
                        </div>
                    </div>
                    <div class="MTB2">
                        场地管理员：{{ToolDoc.SiteAdmins[0]}}；{{ToolDoc.SiteAdmins[1]}}；{{ToolDoc.SiteAdmins[2]}}
                    </div>
                </div>
                <br>
                <div class="XLR YC MP4">&nbsp;
                    <div>分配卡号：<input type="text" v-model="CurrCipher" class="W26"/></div>
                    <div class="AN5 AN52" @click="SetCipher(CurrCipher)">　启用　</div>&nbsp;
                </div>
            </div>
            <div v-if="UserBool==='卡主用户' || UserBool==='场馆人员' && ToolDoc.Cipher!=='' && ToolDoc.Cipher!=='建卡申请'"
                 class="MT2">
                <MyDivider :ShowStr="'['+ToolDoc.Cipher+']号卡刷卡记录'"></MyDivider>
                <div class="XLR YC MP4">
                    <div class="LH150">
                        剩余人次：{{ToolDoc.CurrTimes}}
                    </div>
                    <div class="AN ANYellow" @click="Refresh()">刷新</div>
                </div>
                <table style="background-color:#fff;">
                    <thead class="LH200">
                    <tr class="TF">
                        <th rowspan="2">序号</th>
                        <th rowspan="2">刷卡用户</th>
                        <th colspan="2">刷卡人次</th>
                        <th rowspan="2">状态</th>
                    </tr>
                    <tr class="TF">
                        <th>已刷</th>
                        <th>当前</th>
                    </tr>
                    </thead>
                    <tbody class="LH150">
                    <tr v-for="(C,index) in Cards" :key="index">
                        <td>{{index+1}}</td>
                        <td>
                            <div class="TB" @click="SetSeleI(index)">{{C.CUserName}}</div>
                        </td>
                        <td>{{C.CardVal}}</td>
                        <td class="TE">{{C.CurrVal}}</td>
                        <td>
                            <div v-if="UserBool==='场馆人员' && C.Bool==='待刷卡'" class="MTB2 XLR YC">
                                <div></div>
                                <div class="PA2 BD_Radius1 BGGray TW T4" @click="SetCard4(C._id,index)">取消</div>
                                <div class="PA2 BD_Radius1 BGBlue TW T4" @click="SetCard5(C._id,index)">刷卡</div>
                                <div></div>
                            </div>
                            <div v-else>
                                <div v-if="(UserBool==='卡主用户' || UserBool==='场馆人员') && C.Lists.length>0"
                                     class="LH120 TF">
                                    <div class="BottGray T2">{{StampToMDHM(C.Lists[C.Lists.length-1].Stamp)}}</div>
                                    <div class="T3">{{C.Lists[C.Lists.length-1].CurrVal}}人次</div>
                                </div>
                                <div v-else class="TF T8 MTB2">{{C.Bool}}</div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br>
                <div v-if="UserBool==='卡主用户' && PunchCard && CurrCard.CurrVal===0" style="font-weight: bolder;">
                    <div class="XLR YC MLR4">
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="SelfCard(1)">1人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="SelfCard(2)">2人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="SelfCard(3)">3人次</div>
                    </div>
                    <div class="XLR YC MLR4 MT2">
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="SelfCard(4)">4人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="SelfCard(5)">5人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="SelfCard(6)">6人次</div>
                    </div>
                </div>
            </div>
            <div v-if="UserBool==='刷卡用户'">
                <div class="PA3 TF">
                    <div class="XLR YC">
                        <img :src="'UserUpload/1/S' + ToolDoc.UserLogo" class="AvatarWH">
                        <div class="ML4 LH200 W75" style="overflow: hidden">
                            <div class="T5 BottGray">卡号:{{ToolDoc.Cipher.length===11?
                                                     ToolDoc.Cipher.substr(0,3)+"****"+ToolDoc.Cipher.substr(7,4) : ToolDoc.Cipher }}
                            </div>
                            <div>
                                当前剩余: {{ToolDoc.CurrTimes}}人次
                            </div>
                            每人次＝{{ToolDoc.OneFYB}}个尊享
                        </div>
                    </div>
                    <div class="BottGray">
                        <p v-if="ToolDoc.Text!==''" class="LH150 TC">有效时段：{{ToolDoc.Text}}</p>
                    </div>
                    <div class="MTB2">
                        场地管理员：{{ToolDoc.SiteAdmins[0]}}；{{ToolDoc.SiteAdmins[1]}}；{{ToolDoc.SiteAdmins[2]}}
                    </div>
                </div>
                <br>
                <div v-if="PunchCard && CurrCard.CurrVal===0" style="font-weight: bolder;">
                    <div class="XLR YC MLR4">
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="OtherCard(1)">1人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="OtherCard(2)">2人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7" @click="OtherCard(3)">3人次</div>
                    </div>
                    <div class="XLR YC MLR4 MT2">
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="OtherCard(4)">4人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="OtherCard(5)">5人次</div>
                        <div class="PA2 BD_Radius1 BGBlue TW T7 MT2" @click="OtherCard(6)">6人次</div>
                    </div>
                </div>
                <br>
            </div>
            <div v-if="UserBool==='刷卡用户' || UserBool==='卡主用户'">
                <div v-if="CurrCard.Bool==='待刷卡'">
                    <div class="XLR YC MP4">
                        <div class="TE">等待刷卡......</div>
                        <div v-if="UserBool==='刷卡用户'"></div>
                        <div v-if="UserBool==='刷卡用户'" class="AN ANGray2" @click="SetCard42()">取消</div>
                        <div v-if="UserBool==='刷卡用户'" class="AN ANYellow" @click="Refresh2()">刷新</div>
                    </div>
                    <div class="MP4 BD_Radius1 BGDeepGreen TW T5">
                        <div class="XLR YC T4">
                            <div class="BottGray T3">
                                <div>待刷卡号：<br>&nbsp;&nbsp;&nbsp;{{ToolDoc.Cipher}}&nbsp;</div>
                            </div>
                            {{ThisVal}}人次
                        </div>
                        <div class="MP4 AlignC T7">刷卡用户：{{$store.state.UserName}}</div>
                        <br>
                        <div class="XR T6">卡主：{{ToolDoc.UserName}}</div>
                    </div>
                </div>
                <div class="MT2">
                    <MyDivider ShowStr="我的用卡记录"></MyDivider>
                    <table style="background-color:#fff;">
                        <thead class="LH200 MTB2">
                        <tr class="TF">
                            <th>
                                <div class="LH120 MTB2">用户刷卡时间<br>管理员刷卡时间</div>
                            </th>
                            <th>管理员</th>
                            <th>刷卡人次</th>
                        </tr>
                        </thead>
                        <tbody class="LH200 MTB2">
                        <tr v-for="(CL,index) in CurrCard.Lists" :key="index">
                            <td>
                                <div class="LH120 MTB2">{{StampToTimeStr(CL.Stamp1).indexOf("NaN")>=0 ?
                                                        '':StampToTimeStr(CL.Stamp1)}}<br>{{StampToTimeStr(CL.Stamp)}}
                                </div>
                            </td>
                            <td>{{CL.Admin}}</td>
                            <td>{{CL.CurrVal}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
        </div>
        <div v-if="UserBool==='场馆人员'">
            <WXLink v-if="aLinkKey!==''" :WTitle="aLinkKey+'的共享卡已经启用，需用尊享付账，请点击该链接进入。'"
                    WText="备注：1元人民币＝10个尊享。尊享不足时，请及时补充。" :WLandTo="'FYGXK'+ItemStr+'FYGXK'+aLinkKey"
                    Pic="UserUpload/static/Img/KB.gif"></WXLink>
        </div>
        <PopTitle v-if="ToZXGL" :Title="$store.state.UserName+'的尊享'" @FromPopUp="ToZXGL=false,$emit('GXCardOpen')">
            <ZXGL></ZXGL>
        </PopTitle>
        <PopTitle v-if="SeleI>-1" :Title="Cards[SeleI].CUserName+'的刷卡记录'" @FromPopUp="SeleI=-1">
            <table style="background-color:#fff;">
                <thead>
                <tr class="TF">
                    <th>刷卡时间</th>
                    <th>管理员</th>
                    <th>刷卡人次</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(CL,index) in aLists" :key="index">
                    <td>{{StampToTimeStr(CL.Stamp)}}</td>
                    <td>{{CL.Admin}}</td>
                    <td>{{CL.CurrVal}}</td>
                </tr>
                </tbody>
            </table>
        </PopTitle>
    </div>
</template>

<script>

    import {
        ReGetZX,
        CloneObject,
        myMongoDBPost,
        MyUrl,

        Compare,
        StampToTimeStr, StampToMDHM,
        TransferZX,
        SendWXMsg
    } from '@/components/SharedVues/Shared0.js'
    import MyInput from "../SharedVues/MyInput";
    import PopTitle from "../MyPopWin/PopTitle";

    import FYMap from "../SharedVues/FYMap";
    import ZXGL from "./ZXGL";
    import WXLink from "../IndependentVues/WXLink";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "GXCardOpen",
        components: {MyDivider, WXLink, ZXGL, FYMap, PopTitle, MyInput},
        props: ['Id', 'aLinkKey', 'ItemStr'],
        data() {
            return {
                ToolDoc: null,
                UserBool: '办卡用户',
                CurrId: '',
                Currindex: '',
                CurrCipher: '',

                Cards: [],
                CurrCard: null,
                ThisVal: null,
                NewCard: {
                    ToolsId: this.Id,
                    BeginStamp: 0,
                    EndStamp: 0,
                    CUserName: this.$store.state.UserName,
                    CardVal: 0,
                    CurrVal: 0,
                    Lists: [],//消费记录：[{Stamp:,}]
                    Bool: '─'
                },
                InputI: -1,
                ShowMap: false,
                PunchCard: true,
                ToZXGL: false,
                CurrZX: this.$store.state.ZX,
                SeleI: -1,
                aLists: []
            }
        },
        mounted() {
            if (this.$store.state.UserName === '游客') {
                alert('提示：游客操作无效!');
                this.$emit('GXCardOpen')
            } else this.Refresh();
        },
        methods: {
            SetSeleI(index) {
                let that = this;
                this.SeleI = index;
                myMongoDBPost("GetDocs/Cards", {
                    ToolsId: that.Id,
                    CUserName: this.Cards[index].CUserName
                }, function (Docs) {
                    that.aLists = Docs[0].Lists;
                });
            },
            StampToMDHM(Val) {
                return StampToMDHM(Val);
            },
            Refresh() {
                let that = this;
                myMongoDBPost("GetDocs/SharedCards", {_id: this.Id}, function (Docs) {
                    if (Docs.length > 0) {
                        that.ToolDoc = CloneObject(Docs[0]);
                        that.CurrCard = that.NewCard;

                        if (that.$store.state.UserName === that.ToolDoc.UserName) that.UserBool = '卡主用户';
                        else if (that.ToolDoc.SiteAdmins.indexOf(that.$store.state.UserName) >= 0) that.UserBool = '场馆人员'; else that.UserBool = '刷卡用户';

                        if (['卡主用户', '刷卡用户'].indexOf(that.UserBool) >= 0 && ['', '建卡申请'].indexOf(that.ToolDoc.Cipher) >= 0) {
                            alert('该卡尚未启用！，共享卡启用，须经场馆方同意');
                            that.$emit("GXCardOpen");
                        } else {
                            if (that.UserBool === '卡主用户' && that.ToolDoc.CurrTimes < 1) {
                                alert('该卡已经失效，请删除后再办新卡！');
                                //that.$emit("GXCardOpen");
                            } else if (that.UserBool === '刷卡用户' && that.ToolDoc.CurrTimes < 1) {
                                alert('该卡已经失效，请改刷其它卡！');
                                that.$emit("GXCardOpen");
                            } else if (that.UserBool === '刷卡用户' && that.$store.state.ZX < that.ToolDoc.OneFYB) {
                                alert('尊享不足，不能使用本卡，请先充值！');
                                ReGetZX(that, function (ZX) {
                                    that.CurrZX = ZX;
                                    that.ToZXGL = true;
                                });
                            }
                        }
                        that.RefreshCards();
                    }
                });
            },
            Refresh2() {
                let that = this;
                myMongoDBPost("GetDocs/SharedCards", {_id: this.Id}, function (Docs) {
                    if (Docs.length > 0) {
                        that.ToolDoc = CloneObject(Docs[0]);
                        that.RefreshCards();
                    }
                })
            },
            SetCipher(CurrCipher) {
                let that = this;
                if (CurrCipher !== '') myMongoDBPost("UpdateDoc2/SharedCards", {
                    UPTJ: {_id: that.Id},
                    UPObj: {Cipher: CurrCipher}
                }, function () {
                    that.ToolDoc.Cipher = CurrCipher;
                    that.$emit('GXCardOpen')
                });
            },


            RefreshCards() {
                let that = this;
                myMongoDBPost("GetDocs/Cards", {ToolsId: this.Id}, function (Docs1) {
                    that.Cards = Docs1;//其中包含所有刷卡用户
                    that.Cards.sort(Compare('Bool'));

                    let FindedI = -1;
                    for (let i = 0; i < that.Cards.length; i++)
                        if (that.Cards[i].CUserName === that.$store.state.UserName) {
                            FindedI = i;
                            break;
                        }
                    if (FindedI >= 0) that.CurrCard = Docs1[FindedI]; else that.CurrCard = that.NewCard;//首次用卡

                    if (that.CurrCard.CurrVal === 0) that.PunchCard = true; else {
                        that.ThisVal = that.CurrCard.CurrVal;
                        that.PunchCard = false;
                    }
                });
            },
            SelfCard(CurrVal) {//CurrVal=本次刷卡人次
                this.ThisVal = CurrVal;
                let that = this;
                that.PunchCard = false;
                if (that.ToolDoc.CurrTimes < CurrVal) alert('刷卡失败，该卡仅能再划' + that.ToolDoc.CurrTimes + '次'); else {
                    let aOBJ = {
                        ToolsId: this.Id,
                        CUserName: this.$store.state.UserName,

                        SiteShortName: this.ToolDoc.SiteShortName,
                        UserName: this.ToolDoc.UserName,
                        OneFYB: this.ToolDoc.OneFYB,
                        BeginStamp: new Date().getTime(),
                        Bool: '待刷卡',
                        CurrVal: CurrVal
                    };
                    myMongoDBPost("SelfCard", aOBJ, function () {
                        that.CurrCard.Bool = '待刷卡';
                        that.CurrCard.BeginStamp = new Date().getTime();
                        that.Refresh2();
                    })
                }
            },
            OtherCard(CurrVal) {//需要向卡主this.ToolDoc.UserName转移尊享
                let that = this;
                this.ThisVal = CurrVal;
                myMongoDBPost("GetDocs/Cards", {CUserName: that.$store.state.UserName, Bool: '待刷卡'}, function (Docs1) {
                    if (Docs1.length > 0) {
                        alert('您在“' + Docs1[0].SiteShortName + '”有个“待刷卡”，须先取消或刷卡后，才能够继续刷卡！')
                    } else {
                        if (that.ToolDoc.CurrTimes < CurrVal) return alert('刷卡失败，该卡仅能再划' + that.ToolDoc.CurrTimes + '次');
                        ReGetZX(that, function (ZX) {
                            if (ZX < that.ToolDoc.OneFYB * CurrVal) {
                                alert('您的尊享:' + ZX + '，本次需要：' + that.ToolDoc.OneFYB * CurrVal + '个，请先充值！');
                                ReGetZX(that, function (ZX) {
                                    that.CurrZX = ZX;
                                    that.ToZXGL = true;
                                });
                            } else {
                                let YN = confirm("您有:" + that.$store.state.ZX + "个尊享，现在要用去" + that.ToolDoc.OneFYB * CurrVal + "个" + "===》确认要继续吗？");
                                if (YN) that.SelfCard(CurrVal);
                            }
                        })
                    }
                });
            },

            StampToTimeStr(Val) {
                return StampToTimeStr(Val);
            },
            SetCard4(Id, index) {
                let that = this, asd = CloneObject(that.Cards[index]);
                let aOBJ = {ToolsId: this.Id, CurrVal: -1 * asd.CurrVal, CardsId: Id};
                myMongoDBPost("SetCard4", aOBJ, function () {
                    asd.Bool = '─';
                    asd.CurrVal = 0;
                    that.Cards.splice(index, 1, asd);
                });
            },
            SetCard42() {
                let that = this, FindedI = -1;
                for (let i = 0; i < that.Cards.length; i++)
                    if (that.Cards[i].CUserName === that.$store.state.UserName) {
                        FindedI = i;
                        break;
                    }
                if (FindedI >= 0) {
                    let asd = CloneObject(that.Cards[FindedI]);
                    let aOBJ = {ToolsId: this.Id, CurrVal: -1 * asd.CurrVal, CardsId: asd._id};
                    myMongoDBPost("SetCard4", aOBJ, function () {
                        asd.Bool = '─';
                        asd.CurrVal = 0;
                        that.Cards.splice(FindedI, 1, asd);
                        that.Refresh2();
                    });
                } else alert("请刷新一下再取消!");
            },
            SetCard5(Id, index) {
                let that = this, asd = CloneObject(that.Cards[index]);
                let ListObj = {
                    Stamp1: asd.BeginStamp,
                    Stamp: (new Date()).getTime(),
                    Admin: that.$store.state.UserName,
                    CurrVal: asd.CurrVal
                };
                let aOBJ = {
                    ToolsId: this.Id,
                    CardsId: Id,
                    Admin: that.$store.state.UserName,
                    CurrVal: asd.CurrVal,
                    ListObj: ListObj
                };
                myMongoDBPost("SetCard5", aOBJ, function (data2) {
                    if (data2 === '卡内剩余次数不足!') alert(data2+'请先点击取消，然后通知对方重新刷卡！'); else {
                        asd.Bool = '─';
                        that.ToolDoc.CurrTimes -= asd.CurrVal;
                        asd.CardVal += asd.CurrVal;
                        asd.CurrVal = 0;
                        asd.Lists.push(ListObj);
                        that.Cards.splice(index, 1, asd);
                    }
                });
                if (asd.CUserName !== that.ToolDoc.UserName) {//如果不是卡主消费,应转移尊享
                    let ZXn = that.ToolDoc.OneFYB * asd.CurrVal,
                        Time = StampToTimeStr((new Date()).getTime()).split(" ");
                    let Url = MyUrl('LandTo=Visit0');
                    let fyM0Url = '[ <a href="' + Url + '">查看一下</a> ]';
                    TransferZX(that, asd.CUserName, that.ToolDoc.UserName, ZXn, function (StrZX) {
                        let aTip = '“' + asd.CUserName + '”在' + that.ToolDoc.SiteShortName + '刷卡，转来尊享' + ZXn + '个';
                        if (StrZX === "尊享转移成功!") myMongoDBPost("UpdateDoc/fyuser0", {
                            UPTJ: {UserName: that.ToolDoc.UserName},
                            UPObj: {XYZ: {Tip: aTip, Time1: Time[0], Time2: Time[1]}}
                        }, function () {
                            let TemplateObj = {
                                TemplateIndex: 1,
                                Data: {
                                    thing15: {value: '”在' + that.ToolDoc.SiteShortName + '刷卡'},
                                    thing5: {value: asd.CUserName},
                                    character_string34:  {value:ZXn},
                                    thing48:  {value:'平台管理员'}
                                }
                            }
                            SendWXMsg(that.ToolDoc.UserName, aTip + fyM0Url,TemplateObj);
                        })
                    });
                }
            }
        }
    }
</script>

<style scoped>
</style>
