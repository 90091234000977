<template>
  <div class="XLR YC">
    &nbsp;{{UserName}}
    <div style="line-height: 100%">
      <img v-if="Certified==='成功'" src="../../../static/img/IDed.png" class="Uwh">
      <div v-else>
        <img v-if="Certified==='失败'" src="../../../static/img/ID2.png" class="Uwh">
        <img v-else src="../../../static/img/ID3.png" class="Uwh">
      </div>
      <div class="TE T2">尊享：{{ZX}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserFace",
    props: ["UserName", "Certified","ZX"]
  }
</script>

<style scoped>
</style>
