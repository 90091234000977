<template>
    <img v-if="SeleI!==-1" :src="'UserUpload/1/'+BYs[SeleI].UserLogo" @click="SeleI=-1" class="FullScr2"/>
    <div class="BottGray">
        <TimeSexAge :BYs="BYs" CallFrom="0" :ZyJF="ZyJF" @FromChild="RefreshBYs">注意这里Loaded有一个典型的组件内外传参的示例</TimeSexAge>
    </div>
    <div v-if="Loaded">
        <div v-for="(x,index) in BYs" :key="index" class="BGWhite BottGray LH150"
             :style="x.fyUserName===$store.state.UserName ? 'background-color: #fafafa':''">
            <div v-if="x.ShowBool" :class="'XLR YC PA3 '+(x.SelectedBool ? 'TB':'TF')">
                <img :src="'UserUpload/1/S'+x.UserLogo" @click="SeleI=index" class="AvatarWH2"/>
                <div class="Css4">
                    {{x.fyUserName}}
                    <div class=" XLR YC">
                        <div class="T3">
                            <el-badge v-if="x.AppearanceFee>0" :value="x.AppearanceFee.toString()"></el-badge>
                            {{x.sex}}&nbsp;{{x.age}}
                            <UserFace3 :Certified="x.Certified"></UserFace3>
                        </div>
                        <div v-if="Show===index" class="TG" @click="Show=-1">
                            <el-icon><ArrowUp/></el-icon>
                        </div>
                        <div v-else class="TB" @click="Show=index">
                            <el-icon><ArrowDown/></el-icon>
                        </div>
                    </div>
                </div>
                <div class="W12 AlignC">
                    <input v-if="JFEditQX" type="number" class="W12" @focus="x.JF=null" v-model.number="x.JF" @blur="ChangeJF(x.fyUserName,x.JF)"/>
                    <div v-else class="AN4" @click="SetShowJF(index)">{{x.JF}}</div>
                </div>
                <div class="Css1" style="position: relative;left:1rem;top:-1rem">
                    <ShowTimeWin :TW="x.TimeWinState"></ShowTimeWin>
                </div>
                <div v-if="x.fyUserName===$store.state.UserName" class="Css5">
                    本尊
                </div>
                　
                <div v-else class="Css5 AlignC">
                    <div class="TF T3">{{index+1}}</div>
                    <input type="checkbox" class="aui-switch" v-model="x.SelectedBool"
                           @change="bySelect(x.SelectedBool,x.fyUserName,x.AppearanceFee,x.TimeWinState)"/>
                </div>
            </div>
            <div v-if="Show===index" class="XLR YC TF MLR4">
                <div>
                    <div class="BottGray T2">{{x.domicile.split(".")[0]}}</div>
                    <div class="T2">{{x.domicile.split(".")[1]}}</div>
                </div>
                <ShowPVT :PVT="{P:x.fyUserPic,V:x.fyUserVideo,T:x.fyUserText,Update:false}"></ShowPVT>
                <div>
                    <div class="BottGray T2">人气指数：{{x.PopularityCredit.toFixed(2)}}</div>
                    <div class="T2">综合才艺：{{x.QN.toFixed(2)}}</div>
                </div>
                　
            </div>
        </div>
    </div>
    <div v-else class="MP4 AlignC">IsLoading...</div>
    <ShowJF v-if="ShowJF>-1" :UserName="BYs[ShowJF].fyUserName" :ItemCode="$store.state.CurrItemStr.split(':')[0]"
            :CurrJF="BYs[ShowJF].JF" @FromShowJF="GetNewJF($event,ShowJF)"></ShowJF>
</template>
<script>
    //CurrClub!=='泛约'时,应不再考虑主约前后50名的问题
    import {myMongoDBPost,GetItemType,  Compare} from '@/components/SharedVues/Shared0.js'
    import MySelectStr from "@/components/SharedVues/MySelectStr";
    import TimeSexAge from "./TimeSexAge";
    import ShowTimeWin from "./ShowTimeWin";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import ShowJF from "./ShowJF";
    import UserFace3 from "../SharedVues/UserFace3";

    export default {
        name: "TM0bySele",
        components: {
            UserFace3,
            ShowJF,
            PopOneAN,
            ShowPVT,
            ShowTimeWin,
            TimeSexAge,
            MySelectStr
        },
        props: ['CurrClub', 'ZyJF'],
        data() {
            return {
                Show: -1,
                ShowJF: -1,
                Loaded: false,//数据准备不就绪,就不让它显示
                fyUserName: this.$store.state.UserName,
                loop0: '',
                BYs: [],
                SelectedBYs: [],
                T2Bool: true,
                ItemType: GetItemType(this.$store.state.CurrItemStr.split(":")[0]),
                SeleI: -1,
                JFEditQX: this.CurrClub !== '泛约' && this.$store.state.UserName === this.$store.state.HTM0
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetDocs/fysites", {
                SiteAdmins: this.$store.state.UserName,
                SiteShortName: this.CurrClub
            }, function (Docs) {
                if (that.CurrClub !== '泛约' && Docs.length > 0) that.JFEditQX = true;
                that.GetBYs();
            });

        },
        computed: {
            CurrFYArea() {
                return this.$store.state.FYArea
            }
        },
        watch: {
            CurrFYArea(Val) {
                this.GetBYs();
            }
        },
        methods: {
            SetShowJF(index) {
                if (this.CurrClub === '泛约') {
                    this.ShowJF = index;
                    this.Loaded = false;
                } else alert('只有积分池选择‘泛约’时 ，才能打开积分树；否则，只能看到所选场地会员的积分。会员积分须通过相应场地举办的会员积分赛取得。')

            },
            ChangeJF(UserName, JF) {
                let ItemStr = this.$store.state.CurrItemStr;
                let Obj1 = {
                    UPTJ: {UserName: UserName, ItemStr: ItemStr, "MySites.SiteShortName": this.CurrClub},
                    UPObj: {$set: {'MySites.$.JF': JF}}
                };
                myMongoDBPost("UpdateArrayAttr/byset0", Obj1, function () {

                })//数组元素更新
            },
            GetBYs() {//注意:不带入口参数
                this.Loaded = false;
                let that = this;
                myMongoDBPost("JJYDReadMcData", {
                    ItemCode: this.$store.state.CurrItemStr.split(":")[0],
                    AreaStr: this.$store.state.FYArea,
                    CurrClub: this.CurrClub
                }, function (Docs) {
                    that.SelectedBYs = [];
                    that.BYs = Docs.length > 0 ? Docs.sort(Compare('JF')).reverse() : [];
                    //console.log(data)
                    that.Loaded = true;
                    that.Show = -1;
                    if (that.BYs.length === 0) that.$emit("eventFromTM0", 'NoBY'); else that.$emit("eventFromTM0", that.SelectedBYs);
                });
            },
            RefreshBYs(Val) {
                if (Val === 'CloseBys') this.Loaded = false; else {
                    this.BYs = Val;
                    this.Loaded = true;
                }
            },
            bySelect(CheckedBool, byUserName, AppearanceFee) {
                let that = this;
                myMongoDBPost("GetDocs/fyuser0", {UserName: byUserName}, function (Docs) {
                    if (Docs[0].ZX < AppearanceFee) alert("选择无效!，该被约设定的赢取尊享数量>本人库存数量"); else {
                        let findBool = false;
                        for (let i = 0, byCount = that.SelectedBYs.length; i < byCount; i++) {
                            if (that.SelectedBYs[i]._id === byUserName && CheckedBool === false) { //如果一个当前未选中的被约出现在了被约数组中则将其删除掉
                                that.SelectedBYs.splice(i, 1);
                                return
                            }
                            if (that.SelectedBYs[i]._id === byUserName) findBool = true;
                        }
                        if (findBool === false && CheckedBool === true) that.SelectedBYs.push({
                            "_id": byUserName,
                            "xy": "未响应",
                            "z_zg": "初值",
                            "z_bylyqk": "初值",
                            "b_zg": "初值",
                            "b_zylyqk": "初值",
                            "zg": "初值",
                            "z_ts": "初值",
                            "b_ts": "初值",
                            "Pic": '',//线下活动情况
                            "Video": '',
                            "Text": '',
                            "AppearanceFee": AppearanceFee
                        }); //如果当前选中的被约没有出现在被约数组中则将其追加进去
                        that.$emit("eventFromTM0", that.SelectedBYs);
                    }
                });
            },
            GetNewJF(Val, Index) {
                let asd = this.BYs[Index];
                asd.JF = Val;
                this.BYs.splice(Index, 1, asd);
                this.ShowJF = -1;
                this.Loaded = true;
            }
        }
    }
</script>
<style scoped>
    
    .Css1 {
        width: 10vw;
        text-align: center
        }
    
    .Css4 {
        width: 32vw;
        text-align: left;
        overflow: hidden;
        }
    
    .Css5 {
        width: 15vw;
        text-align: center;
        overflow: hidden;
        }

</style>
