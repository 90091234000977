<template>
    <div class="PA3 TF">
        <div class="XL YC">
            场馆：
            <SetGetOneMySite :ItemStr="ItemStr" @FromSetGetOneMySite="GetSiteMsg"></SetGetOneMySite>
        </div>
        <div>本卡可刷： <input type="number" class="W12 MT2" v-model.number="CurrTool.CurrTimes"
                          @focus="CurrTool.CurrTimes=null"/> 人次
        </div>
        <div>每人次＝ <input type="number" class="W12 MT2" v-model.number="CurrTool.OneFYB"
                         @focus="CurrTool.OneFYB=null"/> 个尊享
        </div>
        <div>
            有效时段：<input type="text" class="W50 MT2" v-model="CurrTool.Text" @focus="CurrTool.Text=null"/>
        </div>
        <div class="MT2">
            场地管理员：{{CurrTool.SiteAdmins[0]}};{{CurrTool.SiteAdmins[1]}};{{CurrTool.SiteAdmins[2]}}
        </div>
    </div>
    <div class="XLR YC">&nbsp;
        <div class="PA5_12 BD_Radius1 BGGray TW T5" @click="$emit('GXCardPub', CurrTool.SiteShortName);">取 消</div>
        <div class="PA5_12 BD_Radius1 BGBlue TW T5" @click="Save">确 定</div> &nbsp;
    </div>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";

    import STable from "../SharedVues/STable";

    import MySele1 from "../SharedVues/MySele1";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";
    import MySelectStr from "../SharedVues/MySelectStr";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import SetMySites from "../M4_Sites/SetMySites";
    import FYArea from "../SharedVues/FYArea";
    import PopTop from "../MyPopWin/PopTop";
    import MyDivider from "../SharedVues/MyDivider";
    import PopBott from "../MyPopWin/PopBott";
    import SetGetOneMySite from "../M4_Sites/SetGetOneMySite";

    export default {
        name: "GXCardPub",
        props: ['ItemStr'],
        components: {
            SetGetOneMySite,
            PopBott,
            MyDivider,
            PopTop,
            FYArea,
            SetMySites,
            PopOneAN,
            MySelectStr,
            ShowUpdateP,
            SeleOneItem,
            PopUpDown,
            MySele1,
            STable,
            MyInput,
            PopTitle,
            ShowUpdatePVT
        },
        data() {
            return {
                CurrTool: {
                    ItemStr: this.ItemStr,
                    UserName: this.$store.state.UserName,//普通卡时为卡主
                    Certified: this.$store.state.Certified,//卡主用户级别
                    UserLogo: this.$store.state.UserLogo,//卡主用户Logo
                    Title: '',//项目名称☛地点提示及场馆名称卡名称
                    SiteAdmins: [],//共享卡时场馆方刷卡员的用户名=最多允许3个
                    Cipher: '',//Cipher='建卡申请' 或 场馆反馈的建卡口令或卡号
                    Text: '',
                    OneFYB: 0,//每次刷卡时的应刷金额
                    CurrTimes: 0,//当前卡剩余金额
                    Province: '',//场馆Province
                    City: '',//场馆City
                    DC: '',
                    Address: '',
                    Longi: 116.38,//经度
                    Lati: 39.9,//纬度
                    SiteName: '',
                    SiteShortName: '',//SiteShortName
                    SiteId: ''
                },
                InputI: -1,
                MySites: [],
                MySiteShortNames: []
            }
        },
        mounted() {
            this.CurrTool.SiteShortName = '?　?　?';
            alert('建新卡须与场地管理员核实信息, 最好面对面创建！');
        },
        methods: {
            GetSiteMsg(Val) {
                console.log(Val)
                if (Val === '场地简称数据对不上') alert('场地原始数据已遭破坏, 无法继续操作，请联系该场地管理员重建场地信息');
                else if (Val.SiteAdmins.length === 0) alert('该场地没有管理员, 无法创建共享卡'); else {
                    this.CurrTool.Province = Val.Province;
                    this.CurrTool.City = Val.City;
                    this.CurrTool.DC = Val.DC;
                    this.CurrTool.Address = Val.Address;
                    this.CurrTool.SiteName = Val.SiteName;
                    this.CurrTool.SiteShortName = Val.SiteShortName;
                    this.CurrTool.SiteAdmins = Val.SiteAdmins;
                    this.CurrTool.Longi = Val.Longi;
                    this.CurrTool.Lati = Val.Lati;
                    this.CurrTool.ScrollTexts = Val.ScrollTexts;
                    this.CurrTool.SiteId = Val._id;
                }
            },
            Save(Val) {
                let that = this;
                this.CurrTool.Title = this.CurrTool.SiteShortName + " ☞ 卡主：" + this.CurrTool.UserName;
                if (Val === -1) this.$emit("GXCardPub", that.CurrTool.SiteShortName);
                else if (that.CurrTool.Title === '' || that.CurrTool.OneFYB <= 0 || that.CurrTool.CurrTimes <= 0 || that.CurrTool.SiteShortName === '') {
                    alert('建卡信息有误, 请检查标题或金额是否正确！');
                    that.$emit("GXCardPub", that.CurrTool.SiteShortName);
                } else {
                    if (that.CurrTool.Cipher === '') that.CurrTool.Cipher = '建卡申请';
                    myMongoDBPost("PutDoc/SharedCards", that.CurrTool, function (data) {
                        that.$emit("GXCardPub", that.CurrTool.SiteShortName);
                    });
                }
            }
        }
    }
</script>

<style scoped>
</style>

