<template>
    <div v-if="QY.UserName===$store.state.UserName" title="打开自己的求缘文档">
        <div class="XLR YC MT2">
            <div class="BottGray TF PA3">有关说明:</div>
            <div>
                <div class="DispIB">
                    <span v-if="QY.Pic.length===0">─</span>
                    <ShowPFromThumb v-else :Msg="{Pic:QY.Pic,Update:false}"></ShowPFromThumb>
                </div>
                <div class="DispIB ML2">
                    <span v-if=" QY.Video.length===0">─</span>
                    <ShowVFromThumb v-else :Msg="{Video: QY.Video,Update:false}"></ShowVFromThumb>
                </div>
            </div>
            <div class="XR MR4 ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SetEndBool(QY._id)">设为<br>完成</div>
        </div>
        <div class="MP4">
            <p>{{QY.Text}}</p>
            <div class="XR MLR4 TF">{{StampToTimeStr(QY.Time)}}</div>
        </div>
        <el-divider> • 响应信息列表 •</el-divider>
        <div class="PA2 BottGray" v-for="(DY,index) in DYs" :key="index">
            <div class="XLR YC">
                <img :src="'UserUpload/1/S' + DY.UserLogo" class="AvatarWH2">
                <div class="ML2" style="width: 75vw;overflow: hidden">
                    <div class="BottO">
                        {{DY.Title}}
                    </div>
                    <div class="XLR YC">
                        {{DY.UserName}}
                        <div>
                            <ShowPVT :PVT="{P:DY.Pic,V:DY.Video,T:DY.Text,Update:false}"></ShowPVT>
                        </div>
                    </div>
                    <div v-if="DY.DYContact.length>0">联系方式：{{DY.DYContact}}</div>
                </div>
            </div>
            <div v-if="DY.UserName==='游客'" class="PA3 AlignC TF">
                游客用户的响应信息不可信!
            </div>
            <div v-else class="XLR YC TF">&nbsp;
                <img src="../../../static/gif2/ArrowR.gif" style="width: 1.5rem;">
                <div class="BGLightGreen BMP">
                    <div class="XLR YC BMP">&nbsp;
                        <div class="T2">留言：</div>
                        <div class="BoxGray1 H W60 MP4 TH" @click="InputI1=index">{{DY.QYContact===''?"―":DY.QYContact}}</div>
                    </div>
                    <div v-if="!RepeatBool && CallFrom==='BB'" class="XLR YC BMP">&nbsp;
                        <div v-if="DY.ZXn===0" class="XLR YC">
                            <div class="TE T3">发放
                                <input type="number" class="W16" v-model.number="ZXn"/> 个尊享
                            </div>
                            <img src='../../../static/img/ButtonPic/AConfirm.png' @click="ZXOut1(DY.UserName, ZXn, index)" class="RCB  Box42 ML2">
                        </div>
                        <p v-else-if="DY.ZXn>0" class="TE">
                            您向{{DY.UserName}}发放的{{DY.ZXn}}个尊享已被接受。
                        </p>
                        <div v-else>
                            尊享已转出，正在等待对方接收...
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MyInput v-if="InputI1!==-1" Title="我的联系方式" Type="text" :Begin="DYs[InputI1].QYContact"
                 @FromMyI="MyInputVal1"></MyInput>
    </div>
    <div v-else title="否则,开始对缘响应">
        <div v-if="CallFrom==='BB'" class="PA3">悬赏：【{{QY.ZXn}}】个尊享</div>
        <ShowPVT3 Curr="" :QY="Msg"></ShowPVT3>
        <el-divider>给他／她留言</el-divider>
        <div class="XR MR4">
            <img src='../../../static/img/ButtonPic/ACancel.png' @click="Quit" class="RCB  Box41">
            <img src='../../../static/img/ButtonPic/AConfirm.png' @click="SaveCurrDYDoc" class="RCB  Box42 ML2">
        </div>
        <div v-if="DY.QYContact!==''" class="MP4 BottO">
            {{CallFrom==='QY' ? '缘主':DY.QYUserName}}留言：{{DY.QYContact}}
        </div>
        <div class="MP4 TF LH150">
            <div class="XL YC">{{CallFrom==='QY' ? '表白': '留言'}}：
                <div class="BoxGray1 W60 H MP4 TH DispIB" @click="InputI2=1">{{DY.Title===''?"―":DY.Title}}</div>
            </div>
            （一句话或关键词组合）<br><br>
            <div class="XL YC">
                详细描述信息：　
                <ShowUpdatePVT v-if="Show1"
                               :PVTF="{P:DY.Pic,V:DY.Video,T:DY.Text,FPre:'DY',TextTitle:(CallFrom==='QY' ? '求缘内容': '留言信息')}"
                               @ShowUT="CallBack2"></ShowUpdatePVT>
            </div>
            <br>
            <div class="XLR YC">
                联系方式：
                <div class="BoxGray1 W60 H PA3 TH DispIB" @click="InputI2=2">{{DY.DYContact===''?"―":DY.DYContact}}</div>
            </div>
            <br><br>
            <div v-if="!RepeatBool && CallFrom==='BB' && DY.ZXn<0" class="XLR YC BGLightGreen MP1">
                {{QY.UserName}}转来{{-1*DY.ZXn}}个尊享
                <div class="AN ANGreen T2" @click="ZXOut2()">接收确认</div>
            </div>
            <br>
            <MyInput v-if="InputI2===1" :Title="CallFrom==='QY' ? '缘份表白': '留言信息'" Type="text" :Begin="DY.Title"
                     @FromMyI="MyInputVal2"></MyInput>
            <MyInput v-if="InputI2===2" Title="联系方式" Type="text" :Begin="DY.DYContact"
                     @FromMyI="MyInputVal2"></MyInput>
            <br>
        </div>
    </div>
</template>

<script>
    import {myMongoDBPost, SendWXMsg, TemplateObj7, TransferZX, StampToTimeStr} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import ShowPVT3 from "@/components/MyPVTs/ShowPVT3";
    import ShowPFromThumb from "@/components/MyPVTs/ShowPFromThumb";
    import ShowVFromThumb from "@/components/MyPVTs/ShowVFromThumb";
    import MyInput from "@/components/SharedVues/MyInput";
    import ShowPVT from "../MyPVTs/ShowPVT";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "OpenOneQY",
        components: {MyDivider, ShowPVT, ShowPVT3, MyInput, ShowVFromThumb, ShowPFromThumb, ShowUpdatePVT},
        props: ['CallFrom', 'Msg'],
        data() {
            return {
                ShowPoppup: false,
                QY: this.Msg,
                DY: {
                    QYUserName: this.Msg.UserName,
                    QYId: this.Msg._id,

                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,

                    ZXn: 0,

                    Title: '',
                    Pic: '',
                    Video: '',
                    Text: '',
                    Time: (new Date()).getTime(),
                    DYContact: '',
                    QYContact: ''
                },
                DYs: [],
                P: '',
                C: '',
                DC: '',
                Show1: false,
                InputI1: -1,
                InputI2: -1,
                RepeatBool: false,
                ZXn: 0
            }
        },
        mounted() {
            let that = this;
            if (that.$store.state.UserName === this.QY.UserName) {
                myMongoDBPost("GetDocs/DYs", {QYId: that.QY._id}, function (Docs) {
                    let aObj = {UPTJ: {_id: that.Msg._id}, UPObj: {DYn: Docs.length, NewDY: false}};
                    myMongoDBPost("UpdateDoc/QYs", aObj, function () {
                    })//一个求缘被打开,是由发布者自己打开的,则应将相应的对缘相应标志设为false
                })
            } else {
                let aObj = {UPTJ: {_id: that.DY.QYId}, UPObj: {$inc: {LookedTimes: 1}}};
                myMongoDBPost("UpdateDoc2/QYs", aObj, function () {
                })
            } //一个求缘被打开,但不是由发布者打开的,则应将相应的LookedTimes:自动增1

            myMongoDBPost("GetDocs/DYs", {QYId: that.Msg._id}, function (Docs) {
                that.DYs = Docs;
                myMongoDBPost("GetDocs/DYs", {QYId: that.Msg._id, UserName: that.$store.state.UserName}, function (Docs2) {
                    if (Docs2.length > 0) that.DY = Docs2[0];
                    that.Show1 = true;
                    that.ShowPoppup = true;
                });
            });
        },
        methods: {
            MyInputVal1(Val) {
                this.DYs[this.InputI1].QYContact = Val;
                this.SaveCurrDYQYContact(this.DYs[this.InputI1]._id, this.DYs[this.InputI1].QYContact);
                this.InputI1 = -1;
            },
            MyInputVal2(Val) {
                if (this.InputI2 === 1) this.DY.Title = Val;
                if (this.InputI2 === 2) this.DY.DYContact = Val;
                this.InputI2 = -1;
            },
            StampToTimeStr(X) {
                return StampToTimeStr(X)
            },
            CallBack1(Val) {
                if (Val.P) this.QY.Pic = Val.P;
                if (Val.V) this.QY.Video = Val.V;
                if (Val.T) this.QY.Text = Val.T;
            },
            SaveCurrDYQYContact(Id, QYContact) {
                let aObj = {UPTJ: {_id: Id}, UPObj: {QYContact: QYContact}};
                myMongoDBPost("UpdateDoc/DYs", aObj, function () {
                })
            },

            Delete(Id) {
                let that = this;
                if (Id !== undefined) myMongoDBPost("RemoveDoc/QYs", {_id: Id}, function () {
                    myMongoDBPost("RemoveDoc/DYs", {QYId: Id}, function () {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneQY");
                    })
                })
            },
            SetEndBool(Id) {
                let that = this, aObj = {UPTJ: {_id: Id}, UPObj: {EndBool: 2}};
                myMongoDBPost("UpdateDoc/QYs", aObj, function () {
                    myMongoDBPost("UpdateDoc/DYs", aObj, function () {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneQY");
                    })
                })
            },
            CallBack2(Val) {
                if (Val.P) this.DY.Pic = Val.P;
                if (Val.V) this.DY.Video = Val.V;
                if (Val.T) this.DY.Text = Val.T;
            },
            SaveCurrDYDoc() {
                let that = this;//如果存在则修改,如果不存在则存入

                let aObj = {UPTJ: {_id: this.DY.QYId}, UPObj: {NewDY: true}};
                myMongoDBPost("UpdateDoc/QYs", aObj, function () {
                    let Fee2 = that.DY.ZXn;
                    if (Fee2 > 0) {
                        alert('向' + that.DY.QYUserName + '转移' + Fee2 + '个尊享！，正在等待对方接收...');
                        that.DY.ZXn = -1 * Fee2;
                    }
                    let UrlSuffix = ' \n[ MyUrlHere ]MyUrlHereMyUrl=' + 'LandTo=QYQWERQYId=' + that.Msg._id + 'QWERCallFrom=' + that.CallFrom + '___';
                    SendWXMsg(that.Msg.UserName, that.$store.state.UserName + ">" + that.Msg.Title + UrlSuffix, TemplateObj7(that));

                    if (that.DY._id) myMongoDBPost("UpdateObjs/DYs", [that.DY], function () {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneQY");
                    }); else myMongoDBPost("PutDoc/DYs", that.DY, function (data) {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneQY");
                    });
                });
            },
            Quit() {
                this.ShowPoppup = false;
                this.$emit("OpenOneQY");
            },

            ZXOut1(ToUserName, ZXn, index) {//出让
                let that = this;
                let ASD = that.DYs[index];
                if (ZXn > 0) {
                    if (ZXn <= that.$store.state.ZX) {
                        ASD.ZXn = -1 * ZXn;
                        that.DYs.splice(index, 1, ASD);
                        myMongoDBPost("UpdateDoc/DYs", {UPTJ: {_id: ASD._id}, UPObj: {ZXn: ASD.ZXn}}, function () {

                        })
                    } else alert('出让失败，剩余尊享数量不足!');
                }
                if (ZXn === 0 || ZXn === null) alert('转让尊享数量有误，请重新输入!');
            },
            ZXOut2() {//出让时接受确认
                let that = this, FromUserName = that.QY.UserName;
                this.RepeatBool = true;
                TransferZX(that, FromUserName, that.$store.state.UserName, -1 * that.DY.ZXn, function (asd) {
                    if (asd === '尊享转移成功!') {
                        alert('收到尊享，来自' + FromUserName + '，共计' + (-1 * that.DY.ZXn) + '个！');
                        myMongoDBPost("UpdateDoc/DYs", {UPTJ: {_id: that.DY._id}, UPObj: {ZXn: -1 * that.DY.ZXn}}, function () {
                        })
                    }
                })

            },
        }
    }
</script>

<style scoped>
</style>
