import axios from 'axios'

function ArrayUnique(data) { //入口参数data:待压缩数组
    data = data || [];
    let i, a = {};
    for (i = 0; i < data.length; i++) {
        let v = data[i];
        if (typeof(a[v]) === 'undefined') {
            a[v] = 1;
        }
    }
    data.length = 0;
    for (i in a) data[data.length] = i;
    return data;
} //数组元素唯一性压缩:注意调用方法:ArrayUnique(countries);数组唯一性

//用户注销时,应window.localStorage.clear();
function UpdatelocalStorage(Bool, NewValue) {
    if (window.localStorage && window.localStorage.M1) {
        let asd = window.localStorage.M1.split("-=_+");
        if (Bool === 'fyUserName') window.localStorage.M1 = NewValue + "-=_+" + asd[1] + "-=_+" + asd[2];
    }
    if (window.localStorage && window.localStorage.M2) {
        let aOBJ = JSON.parse(window.localStorage.M2);//字符串转换为对象
        if (Bool === 'FYArea') aOBJ.FYArea = NewValue;
        if (Bool === 'ZX') aOBJ.ZX = NewValue;
        window.localStorage.M2 = JSON.stringify(aOBJ);//对象转换为字符串
    }
}

function GetWxInterface(CallBack) {
    const script = document.createElement('script');
    script.src = 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    script.onload = () => {// 外部脚本加载完成后执行的代码
        let aUrl = window.location.href.split('#')[0];
        //微信接口注入权限验证配置
        if (UrlFrom() === '微信') myWXpost("GetConfigData", {
            nonceStr: MyRandomCode(20),
            timestamp: (new Date()).getTime(),
            url: aUrl
        }, function (aObj) {
            wx.config({
                debug: false, // true=开启调试模式,调用的所有api的返回值会在客户端alert出来
                appId: aObj.appId, // 必填，公众号的唯一标识
                timestamp: aObj.timestamp, // 必填，生成签名的时间戳
                nonceStr: aObj.nonceStr, // 必填，生成签名的随机串
                signature: aObj.signature,// 必填，签名，见附录1
                jsApiList: ["miniProgram", "closeWindow", "openLocation", "getLocation", "setStorageSync", "getStorageSync"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(function () {
                CallBack('wx.正常')
            });
            wx.error(function () {
                CallBack('wx.异常')
            });
        });
    };
    document.head.appendChild(script);
}

function Compare(Property) {
    return function (a, b) {
        let val1 = a[Property];
        let val2 = b[Property];
        if (val2 < val1) {
            return 1;
        } else if (val2 > val1) {
            return -1;
        } else {
            return 0;
        }
    }
}

function LocaleCompare(Property) {
    return function (a, b) {
        let val1 = a[Property];
        let val2 = b[Property];
        return val1.localeCompare(val2);
    }
}

function MyUrl(MyUrlPara) {
    let Url1 = 'http://www.fy135.vip/?' + MyUrlPara;
    let Url2 = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd4ed5f6c863d26c3&redirect_uri=' + Url1 + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect';
    return Url2;

}

function ZXChanged(that, ZX) {
    that.$store.commit('ChangeZX', ZX);
    UpdatelocalStorage('ZX', ZX)
}

function ReGetZX(that, Callback) {
    myMongoDBPost("GetDocs/fyuser0", {UserName: that.$store.state.UserName}, function (Docs1) {
        let ZX = 0;
        if (Docs1[0] && Docs1[0].ZX) {
            that.$store.commit('ChangeZX', Docs1[0].ZX);
            ZX = Docs1[0].ZX;
        }
        Callback(ZX)
    })
}

function KeepZX(that, UserName, IOType, ZXn, Describe, Callback) {
    if (UserName === "游客") Callback(); else {
        myDataFileGet("DataFile/GetStrFromServer", function (aStr) {
            myMongoDBPost("KeepZXs", {fyUserName: UserName, IOType: IOType, ZXn: ZXn, Describe: Describe + aStr + ZXn}, function (StrZX) {
                if (StrZX !== "无效" && UserName === that.$store.state.UserName) ZXChanged(that, parseInt(StrZX));
                Callback();
            })
        })
    }
}//尊享收入与支出处理　
function TransferZX(that, From, To, ZXn, Callback) {
    if (From !== To && ZXn > 0 && From !== "游客" && To !== "游客") myMongoDBPost("GetDocs/fyuser0", {UserName: From}, function (Docs1) {
        if (Docs1[0].ZX < ZXn) Callback(From + "的尊享余量不足，尊享转移失败!"); else
            KeepZX(that, From, '支', ZXn, '转给' + To, function () {
                KeepZX(that, To, '收', ZXn, '从' + From + '转来', function () {
                    Callback('尊享转移成功!');
                });
            });
    }); else Callback('无效的尊享转移')
}//尊享转移
function Trim(s) {
    return s.replace(/(^\s*)|(\s*$)/g, "");
}//去掉字符串前后的空格
function MyRandomCode(CodeLength) {
    let UniqueCode = "", Chars = "abcdefghijkmnpqrstuvwxyz23456789";//32位去掉了01lo
    let CharsLength = Chars.length;
    for (let i = 0; i < CodeLength; i++) {
        let charIndex = Math.floor(Math.random() * CharsLength);
        UniqueCode += Chars[charIndex];
    }
    if (UniqueCode.length !== CodeLength) {
        MyRandomCode(CodeLength);
    } else return UniqueCode;
}//随机码:定长8位时,共有8亿多个码



function weixnVersion() {
    let wechatInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i);
    console.log("wechatInfo=" + wechatInfo)
    if (wechatInfo) return wechatInfo[1] || -1; else return -1;
}

function UrlFrom() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") > -1) return '微信';
    else if (ua.indexOf("mqqbrowser") > -1) return 'QQ浏览器';
    else return '其它';
}//js判断是否在微信浏览器中打开

function IsApple() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("iphone") > -1) return '苹果'; else return '其它';
}//js判断是否苹果手机
/*
function UrlParse() {
  let url = window.location.search;
  let obj = {};
  let asd1=url.split("?")[1].split("___")[0], arr = asd1.split("!");

  if (arr) {
    arr.forEach(function (item) {
      let tempArr = item.substring(0).split('=');
      let key = decodeURIComponent(tempArr[0]);
      //let val = decodeURIComponent(tempArr[1]);
      obj[key] = decodeURIComponent(tempArr[1]);//val;
    });
  }
  return obj;
}
*/
function UrlParse() {
    let url = window.location.search;
    let obj = {};
    let FYReg = /[?&][^?&]+=[^?&]+/g;
    let arr = url.match(FYReg);
    if (arr) {
        arr.forEach(function (item) {
            let tempArr = item.substring(1).split('=');
            let key = decodeURIComponent(tempArr[0]);
            //let val = decodeURIComponent(tempArr[1]);
            obj[key] = decodeURIComponent(tempArr[1]);//val;
        });
    }

    if (url.indexOf("___") >= 0) {
        let asd1 = url.split("?")[1].split("___")[0], arr = asd1.split("QWER");//在任俊峰的软件里QWER就是分隔符

        if (arr) {
            arr.forEach(function (item) {
                let tempArr = item.substring(0).split('=');
                let key = decodeURIComponent(tempArr[0]);
                obj[key] = decodeURIComponent(tempArr[1]);//val;
            });
        }
    }


    return obj;
}


function DeleteMsgDetails(Id) {
    myMongoDBPost("GetDocs/MsgDetails", {MsgId: Id}, function (Docs) {
        for (let i = 0, len = Docs.length; i < len; i++)
            (function (i) {
                let P = Docs[i].Str1, V = Docs[i].Str1;
                if (Docs[i].MType === '图片') myVodpost("DeleteMedia", {FileId: P}, function () {
                });//删除图片
                if (Docs[i].MType === '视频') myVodpost("DeleteMedia", {FileId: V}, function () {
                });//删除视频
            })(i)//采用匿名函数闭包的方法解决循环内有回调的问题
        if (Id !== undefined) myMongoDBPost("RemoveDoc/MsgDetails", {MsgId: Id}, function () {
        });
    });
}

function myDataFileGet(Url, Callback) {
    axios.get('DataFile/' + Url)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        console.log("error=" + error);
        Callback('网络出错啦!')
    })
}
function myDataFilePost(Url, aObj, Callback) {
    axios.post('DataFile/' + Url, aObj)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        //console.log("error=" + error);
        Callback('网络出错啦!')
    })
}

function myMongoDBPost(Url, aObj, Callback) {
    axios.post('myMongoose/' + Url, aObj)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        console.log("myMongoDBPost error=" + error + " || Url=" + Url);
        Callback('网络出错啦!')
    })
} //为安全起见,禁用 myMongoDBGet

function myAlipay(Url, aObj, Callback) {
    axios.post('myAlipay/' + Url, aObj)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        //console.log("error=" + error);
        Callback('网络出错啦!')
    })
}

function myWXget(Url, Callback) {
    axios.get('MyWeixin/' + Url)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        //console.log("error=" + error);
        Callback('网络出错啦!')
    })
}

function myWXpost(Url, aObj, Callback) {
    axios.post('MyWeixin/' + Url, aObj)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        //console.log("error=" + error);
        Callback('网络出错啦!')
    })
}

function myVodpost(Url, aObj, Callback) {
    axios.post('MyVod/' + Url, aObj)
        .then(function (Res) {
            Callback(Res.data)
        }).catch(function (error) {
        Callback('网络出错啦!')
    })
}

function SendWXMsg(ToFYUserName, MsgStr, TemplateObj) {
    myWXpost("SendWXMsg", {ToFYUserName: ToFYUserName, MsgStr: MsgStr, MsgType: 'custom', TemplateObj: TemplateObj}, function () {

    });
}

function SendWXPicMsg(ToFYUserName, PicBool) {
    myWXpost("SendWXPicMsg", {ToFYUserName: ToFYUserName, PicBool: PicBool}, function () {
        if (PicBool === '收款码') alert('已为您调出了二维收款码, 请在公众号界面找到它，然后点开再长按识别！');
    });
}

function SendWX_PTMsg(ToFYUserName, Title, Description, Url, PicUrl) {
    myWXpost("SendWX_PTMsg", {ToFYUserName: ToFYUserName, Title: Title, Description: Description, Url: Url, PicUrl: PicUrl}, function () {
    });
}

function CloneObject(obj) {  //通用对象克隆函数
    let o;
    if (typeof obj == "object") {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (let i = 0, len = obj.length; i < len; i++) {
                    o.push(CloneObject(obj[i]));
                }
            } else {
                o = {};
                for (let j in obj) {
                    o[j] = CloneObject(obj[j]);
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

function ObjAttrName(x) {
    let aArray = [];
    for (let i in x) aArray.push(i)
    return aArray
}

function getUniqueCode() {
    return "" + (new Date()).getTime() + Math.round(10000 * Math.random());
}

function getSexFromId(theId) {
    return (((['1', '3', '5', '7', '9'].indexOf(theId.charAt(16))) < 0) ? "女" : "男");
}//根据身份证号提取性别
function getAgeFromId(theId) {
    let theAge = (new Date()).getYear() - parseInt(theId.slice(8, 10));
    if (theAge > 100) theAge -= 100;
    return theAge;
} //根据身份证号提取年龄:当前年份减去出生年份, 如果大于100就再减100
function getBirthDayFromId(theId) {
    return theId.slice(6, 10) + "年" + theId.slice(10, 12) + "月" + theId.slice(12, 14) + "日";
} //根据身份证号提取性别
function getConstellationFromId(theId) {
    let theM = parseInt(theId.slice(10, 12)), theD = parseInt(theId.slice(12, 14));
    if (theM === 1 && theD > 20 || theM === 2 && theD < 19) return '水瓶';
    if (theM === 2 && theD > 18 || theM === 3 && theD < 21) return '双鱼';
    if (theM === 3 && theD > 20 || theM === 4 && theD < 20) return '白羊';
    if (theM === 4 && theD > 19 || theM === 5 && theD < 21) return '金牛';
    if (theM === 5 && theD > 20 || theM === 6 && theD < 22) return '双子';
    if (theM === 6 && theD > 21 || theM === 7 && theD < 23) return '巨蟹';
    if (theM === 7 && theD > 22 || theM === 8 && theD < 23) return '狮子';
    if (theM === 8 && theD > 22 || theM === 9 && theD < 23) return '处女';
    if (theM === 9 && theD > 22 || theM === 10 && theD < 24) return '天秤';
    if (theM === 10 && theD > 23 || theM === 11 && theD < 23) return '天蝎';
    if (theM === 11 && theD > 22 || theM === 12 && theD < 22) return '射手';
    if (theM === 12 && theD > 21 || theM === 1 && theD < 20) return '摩羯';
}//根据身份证号提取星座
const DateStr2 = date => {
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${[month, day].map(formatNumber).join('.')}`
}
const Week = date => {
    return date.getDay()
}
const TimeStr = date => {
    const hour = date.getHours()
    const minute = date.getMinutes()
    return `${[hour, minute].map(formatNumber).join(':')}`
}

const TimeStr2 = date => {
    const hour = date.getHours()
    const minute = date.getMinutes();
    const Second = date.getSeconds();
    return `${[hour, minute, Second].map(formatNumber).join(':')}`
}

const formatNumber = n => {
    n = n.toString();
    return n[1] ? n : `${n}`
}

function CurrTimeStamp() {
    let asd = new Date();
    return asd.getTime();
}//当前时间的时间戳
function StampToTimeStr(Stamp) {
    let asd1 = ('' + Stamp).length > 10 ? Stamp : Stamp * 1000;
    console.log(asd1);
    let asd = new Date(Stamp);
    //let asd = new Date(1403058804000);
    console.log(asd);
    let Y = asd.getFullYear() + '-';
    let M = (asd.getMonth() + 1 < 10 ? '0' + (asd.getMonth() + 1) : asd.getMonth() + 1) + '-';
    let D = asd.getDate() + ' ';
    let h = asd.getHours() + ':';
    let m = asd.getMinutes() + ':';
    let s = asd.getSeconds();
    return Y + M + D + h + m + s;
    //return new Date(parseInt(Stamp) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
}//时间戳转换为时间字符串格式:1970/1/1 上午8:00
function StampToMDHM(Stamp) {
    let asd = new Date(Stamp);
    let M = (asd.getMonth() + 1 < 10 ? '0' + (asd.getMonth() + 1) : asd.getMonth() + 1) + '-';
    let D = asd.getDate() + ' ';
    let h = asd.getHours() + ':';
    let m = asd.getMinutes();
    return M + D + h + m;
    //return new Date(parseInt(Stamp) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
}//时间戳转换为时间字符串格式:1:1 8:00=月日时分
function StampToDate(Stamp) {
    if (Stamp === undefined) return '';
    let Stamp2 = ('' + Stamp).length > 10 ? Stamp : Stamp * 1000;
    let asd = new Date();
    asd.setTime(Stamp2);
    let asd1 = asd.Format("yy.MM.dd"); //   asd.format('yyyy-MM-dd');//asd.format('yyyy-MM-dd h:m:s'); asd.toLocaleDateString();
    return asd1;
}//时间戳转换为时间字符串格式:70.1.1
function StampToDate2(Stamp) {
    if (Stamp === undefined) return '';
    let asd = new Date();
    asd.setTime(Stamp);
    let asd1 = asd.Format("MM.dd"); //   asd.format('yyyy-MM-dd');//asd.format('yyyy-MM-dd h:m:s'); asd.toLocaleDateString();
    return asd1;
}//时间戳转换为时间字符串格式:1970/1/1
function CurrDateStr() {
    let Now = new Date();
    return "" + Now.getFullYear() + "年" + (Now.getMonth() + 1) + "月" + Now.getDate() + "日";
}//获取当天日期字符串
Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        "S": this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
    // 例子：
    // (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
    // (new Date()).Format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
}
Date.prototype.DateAdd = function (strInterval, Number) {
    var dtTmp = this;
    switch (strInterval) {
        case 's' :
            return new Date(Date.parse(dtTmp) + (1000 * Number));
        case 'n' :
            return new Date(Date.parse(dtTmp) + (60000 * Number));
        case 'h' :
            return new Date(Date.parse(dtTmp) + (3600000 * Number));
        case 'd' :
            return new Date(Date.parse(dtTmp) + (86400000 * Number));
        case 'w' :
            return new Date(Date.parse(dtTmp) + ((86400000 * 7) * Number));
        case 'q' :
            return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number * 3, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
        case 'm' :
            return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
        case 'y' :
            return new Date((dtTmp.getFullYear() + Number), dtTmp.getMonth(), dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
    }
}//入口参数：strInterval＝y|m|q|w|... ,数字如：h,1
function GetRelativeTime(strInterval, Number, fmt) {
    let asd = ((new Date()).DateAdd(strInterval, Number)).Format(fmt);
    console.log(asd);
    return asd;
}

function GetC_I(ItemCode) {
    let asd = ItemCode.trim().charAt(0), Obj = {A: 0, B: 1, C: 2, D: 3};
    return Obj[asd];
}//通过项目代码或ItemStr获取项目频道序号
function GetC_N(Code) {
    let asd = Code.trim().charAt(0), Obj = {A: "对抗竞技", B: "休闲娱乐", C: "技能服务", D: "特邀"};
    if (asd !== 'A' && asd !== 'B' && asd !== 'C' && asd !== 'D') return -1; else return Obj[asd];
}//通过项目代码或项目类型代码获取项目频道名称

function GetItemType(ItemCode) {
    let asd = ItemCode.trim()
    if (asd.charAt(0) === 'A') {
        if (asd.charAt(1) === '0') return '室内项目';
        if (asd.charAt(1) === '1') return '室外项目';
    }
    if (asd.charAt(0) === 'B') {
        if (asd.charAt(1) === '0') return '休闲娱乐';
        if (asd.charAt(1) === '1') return '话题聊天';
        if (asd.charAt(1) === '2') return '同程旅游';
        if (asd.charAt(1) === '3') return '饭局集会';
        if (asd.charAt(1) === '4') return '外语交流';
        if (asd.charAt(1) === '5') return '音乐舞蹈';
        if (asd.charAt(1) === '6') return '戏剧弹唱';
        if (asd.charAt(1) === '7') return '武术杂技';
        if (asd.charAt(1) === '8') return '其它项目';
    }
    if (asd.charAt(0) === 'C') {
        if (asd.charAt(1) === '0') return '家政服务';
        if (asd.charAt(1) === '1') return '开发设计';
        if (asd.charAt(1) === '2') return '咨询策划';
        if (asd.charAt(1) === '3') return '文案写作';
        if (asd.charAt(1) === '4') return '营销推广';
        if (asd.charAt(1) === '5') return '代办代理';
        if (asd.charAt(1) === '6') return '家教补习';
        if (asd.charAt(1) === '7') return '教练陪练';
        if (asd.charAt(1) === '8') return '工匠技能';
        if (asd.charAt(1) === '9') return '门店场所';
    }
    if (asd.charAt(0) === 'D') {
        if (asd.charAt(1) === '0') return '名人大家';
        if (asd.charAt(1) === '1') return '讲堂名师';
        if (asd.charAt(1) === '2') return '评估裁判';
        if (asd.charAt(1) === '3') return '行业专家';
        if (asd.charAt(1) === '4') return '贵宾VIP';
    }
}//通过项目代码获取项目类型
function GetItemStr3(ItemStr) {
    let Code = ItemStr.split(":")[0], CHs = ['竞技>', '休闲>', '劳服>', '特邀>'];
    return CHs[GetC_I(Code)] + GetItemType(Code) + ":" + ItemStr.split(":")[1];
}

/*
function GetUserFace(Certified) {
  if (Certified === '') return '？';//尚未实名认证
  if (Certified === '成功') return '☺';//已通过实名认证
  if (Certified === '失败') return '≠';//实名认证失败
  //if (Certified === '初级会员') return '☆';
  //if (Certified === '中级会员') return '☆☆';
  //if (Certified === '高级会员') return '☆☆☆';
};
*/


function FillUserlogs(that, Looked) {
    //if (that.$store.state.UserName !== that.$store.state.HTM0)
    myMongoDBPost("PutDoc/userlogs", {
        LoginTime: new Date().getTime(),
        UserName: that.$store.state.UserName,
        LandTo: that.$store.state.LandTo,
        Looked: Looked
    }, function (res) {
        console.log(res._id)
    })
}

function DeletePV(P, V) {
    if (P !== '') myVodpost("DeleteMedia", {FileId: P}, function () {
    });//删除图片
    if (V !== '') myVodpost("DeleteMedia", {FileId: V}, function () {
    });//删除视频
}//删除当前文档中的照片和视频,如果存在的话

function TemplateObj6(X, Y, Z) {
    return {
        TemplateIndex: 6,
        Data: {
            character_string2: {value: 'FyHelpYou_' + X.slice(-10)},//订单编号
            thing16: {value: Y.replace(/\s*/g, "")},//客户名称
            thing8: {value: Z.replace(/\s*/g, "")}//服务名称
        }
    }
}

function TemplateObj7(that) {
    return {
        TemplateIndex: 7,
        Data: {
            thing31: {value: that.Msg.Title.replace(/\s*/g, "")},//项目名称,中间不能有空格 去除所有空格
            thing5: {value: that.$store.state.UserName.replace(/\s*/g, "")},//姓名
            thing8: {value: that.DY.Title.replace(/\s*/g, "")}//工单名称
        }
    }
}

function GetProvincialCapital(P) {
    let AllPC = {
        '北京': "北京市",
        '天津': "天津市",
        '河北': "石家庄市",
        '山西': "太原市",
        '内蒙古': "呼和浩特市",
        '辽宁': "沈阳市",
        '吉林': "长春市",
        '黑龙江': "哈尔滨市",
        '河南': "郑州市",
        '湖北': "武汉市",
        '湖南': "长沙市",
        '广东': "广州市",
        '广西': "南宁市",
        '海南': "海口市",
        '上海': "上海市",
        '江苏': "南京市",
        '浙江': "杭州市",
        '安徽': "合肥市",
        '福建': "福州市",
        '江西': "南昌市",
        '山东': "济南市",
        '重庆': "重庆市",
        '四川': "成都市",
        '贵州': "贵阳市",
        '云南': "昆明市",
        '西藏': "拉萨市",
        '陕西': "西安市",
        '甘肃': "兰州市",
        '青海': "西宁市",
        '宁夏': "银川市",
        '新疆': "乌鲁木齐市",
        '台湾': "台北市",
        '香港': "香港",
        '澳门': "澳门"
    };
    if (AllPC[P]) return AllPC[P]; else return "天津市";
}

function is_weixn() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1;
}//js判断是否在微信浏览器中打开
let Weeks = ['一', '二', '三', '四', '五', '六', '日'];
let HourSegs = ['晨练', '早点', '上午', '午餐', '下午', '课外', '晚餐', '晚上'];
let Years = ['2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036'];
let Months = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
let Holidays = ['元旦', '春节', '清明节', '五一劳动节', '端午节', '中秋节', '国庆节', '妇女节', '青年节', '儿童节', '建军节', '星期六', '星期日'];

let Sleeps = [{Type: '差', Opts: ['彻夜未眠', '难以入睡', '间断性失眠', '晨醒过早', '嗜睡不醒']}, {Type: '好', Opts: ['正常睡眠', '一觉到天明']}];
let Zhus = [
    {Type: '米', Opts: ['大米', '玉米', '黑米', '小米', '糯米', '糙米', '大黄米', '高粱']},
    {Type: '麦', Opts: ['小麦', '全麦', '燕麦', '莜面', '大麦', '荞麦', '黑麦']},
    {Type: '豆', Opts: ['绿豆', '黄豆', '黑豆', '红豆', '蚕豆', '豌豆', '扁豆', '芸豆', '杂豆']},
    {Type: '薯', Opts: ['红薯', '土豆', '芋头', '山药', '木薯']},
    {Type: '加工品', Opts: ['油条', '水饺', '馄饨', '烙饼', '包子', '炒米饭', '打卤面', '一锅面', '木糖醇饼干']}];
let ZhuFs = [{Type: '药膳', Opts: ['降糖粉']},
    {Type: '做法', Opts: ['煎', '炒', '炸', '蒸', '烤', '炖', '煮']},
    {Type: '粥汤类', Opts: ['大米粥', '小米粥', '燕麦粥', '玉米糊', '杂粮粥', '糊辣汤', '豆腐脑', '卤汤']},
    {Type: '饮品', Opts: ['豆浆', '牛奶', '咖啡', '奶咖', '绿茶', '红茶', '白酒', '干红', '果酒', '啤酒']},
    {Type: '水果类', Opts: ['雪莲果', '西瓜', '桔橙柚', '葡萄', '梨', '桃', '杏', '香蕉', '苹果', '草莓', '枣类', '火龙果', '芒果', '椰子']},
    {Type: '干果', Opts: ['花生', '核桃', '杏仁', '瓜子', '碧根果', '黑豆', '蚕豆', '爆米花']},
    {Type: '配菜', Opts: ['泡菜', '腌咸菜', '腐乳', '豆制品', '面筋', '豆腐', '海带', '木耳', '蘑菇']},
    {Type: '调料', Opts: ['葱', '姜', '蒜', '辣椒', '大油', '咖喱', '酱油', '醋', '蜂蜜', '糖']}];
let Huns = [{Type: '红肉', Opts: ['猪肉', '羊肉', '牛肉', '狗肉', '鹿肉', '兔肉', '腊肉']}, {
    Type: '白肉',
    Opts: ['鸡', '鸭', '鱼', '虾', '蟹', '海参']
}, {
    Type: '下水',
    Opts: ['猪杂', '鸡杂', '羊杂', '牛杂']
}, {Type: '蛋类', Opts: ['鸡蛋', '鸭蛋']}];
let Sus = [
    {Type: '牙苗类', Opts: ['绿豆芽', '黄豆芽', '蒜苗', '蒜黄', '蒜苔', '豌豆苗', '香椿']},
    {Type: '叶菜类', Opts: ['白菜', '菠菜', '芹菜', '韭菜', '油麦菜', '生菜', '菜花', '茼蒿', '荠菜']},
    {Type: '根茎类', Opts: ['胡萝卜', '白萝卜', '山药', '红薯', '藕', '竹笋', '芋头', '土豆']},
    {Type: '瓜果类', Opts: ['黄瓜', '冬瓜', '南瓜', '西葫芦', '丝瓜', '豆角', '茄子', '西红柿', '苦瓜', '青椒']}];
let YDs = [{Type: '无汗', Opts: ['无运动', '正常走']}, {Type: '有汗', Opts: ['10分钟', '20分钟', '30分钟', '40分钟', '1小时', '90分钟']}, {
    Type: '剧烈',
    Opts: ['剧烈10', '剧烈20', '剧烈30', '剧烈40', '剧烈60', '剧烈90']
}];
export {
    TemplateObj6,
    TemplateObj7,
    ZXChanged,
    Sleeps,
    Zhus, ZhuFs, Huns, Sus, YDs,
    Weeks, HourSegs,
    ArrayUnique, IsApple,
    UpdatelocalStorage, Trim,
    MyRandomCode,
    DeletePV,
    myDataFileGet,
    myDataFilePost,
    myMongoDBPost,
    myAlipay,
    weixnVersion,
    is_weixn,
    myWXget,
    myWXpost, myVodpost,
    SendWXMsg,
    SendWX_PTMsg,
    CloneObject,
    CurrTimeStamp,
    StampToTimeStr,
    StampToDate,
    StampToDate2,
    GetRelativeTime, DateStr2, Week, TimeStr, TimeStr2,
    GetItemType,
    GetItemStr3,
    ObjAttrName,
    Years,
    Months,
    Holidays,
    GetC_I,
    GetC_N,
    getUniqueCode,
    CurrDateStr, Compare,
    UrlParse,
    UrlFrom,
    GetProvincialCapital,
    DeleteMsgDetails,
    getSexFromId,
    getAgeFromId,
    getBirthDayFromId,
    getConstellationFromId,
    KeepZX, TransferZX,
    FillUserlogs, GetWxInterface,
    MyUrl, SendWXPicMsg, ReGetZX, StampToMDHM, LocaleCompare
}
